import Logo1 from "../../images/logo-pizzawlape.png";
import Logo2 from "../../images/triumph.png";
import Logo3 from "../../images/bong-bong.png";
import Logo4 from "../../images/domkove.png";
import Logo5 from "../../images/zdrowa-krowa.png";
import Logo6 from "../../images/yeah-bunny.png";
import Logo7 from "../../images/logo-crazychicken.png";
import Logo8 from "../../images/logo-bs.png";
import Logo9 from "../../images/brzytwa-mac.png";
import Logo10 from "../../images/logo-hopsa.png";
import Logo11 from "../../images/logo-fryzjerek.png";
import Logo12 from "../../images/logo-da-vella.png";
import Logo13 from "../../images/seromaniak.png";
import Logo14 from "../../images/zdrowe-love.png";

export const logos = [
  {
    src: Logo2,
    alt: "triumph",
  },
  {
    src: Logo3,
    alt: "bong-bong",
  },
  {
    src: Logo4,
    alt: "domkove",
  },
  {
    src: Logo5,
    alt: "zdrowa krowa",
  },
  {
    src: Logo6,
    alt: "yeah-bunny",
  },
  {
    src: Logo9,
    alt: "brzytwa-mac",
  }, 
  {
    src: Logo14,
    alt: "zdrowe-love",
  },
];
