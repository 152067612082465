import React from "react";

type Props = {
  color?: string;
  width?: number;
  height?: number;
};

const Chevron: React.FC<Props> = ({
  color = "#fff",
  width = 8,
  height = 14,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71934 0.280662C7.6306 0.191696 7.52518 0.121111 7.40911 0.0729501C7.29305 0.0247895 7.16863 0 7.04297 0C6.91731 0 6.79289 0.0247895 6.67683 0.0729501C6.56076 0.121111 6.45534 0.191696 6.3666 0.280662L0.63466 6.0126C0.545694 6.10134 0.47511 6.20676 0.42695 6.32283C0.378788 6.43889 0.353998 6.56331 0.353998 6.68897C0.353998 6.81463 0.378788 6.93905 0.42695 7.05511C0.47511 7.17118 0.545694 7.2766 0.63466 7.36534L6.3666 13.0973C6.54598 13.2767 6.78928 13.3774 7.04297 13.3774C7.29666 13.3774 7.53995 13.2767 7.71934 13.0973C7.89872 12.9179 7.9995 12.6746 7.9995 12.4209C7.9995 12.1672 7.89872 11.9239 7.71934 11.7445L2.66186 6.68897L7.71934 1.6334C7.8083 1.54466 7.87889 1.43924 7.92705 1.32317C7.97521 1.20711 8 1.08269 8 0.957031C8 0.831373 7.97521 0.706949 7.92705 0.590886C7.87889 0.474824 7.8083 0.369403 7.71934 0.280662Z"
      fill={color}
    />
  </svg>
);

export { Chevron };
